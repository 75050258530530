import "../assets/scss/styles.scss";
import "@carybit/lead-generation-form/dist/index.css";
import configFile from "../../config.json";
import { LeadGenerationForm, IConfig } from "@carybit/lead-generation-form";
import { CustomQuote } from "./CustomQuote";
import { useEffect, useState } from "react";

const App = () => {
    const [config, setConfig] = useState<IConfig>(configFile as any);
    const [isCustomQuote, setIsCustomQuote] = useState(false);

    useEffect(() => {
        setConfig({ ...config, hiddenPages: isCustomQuote ? [] : ["customQuote"], lastPageWithProgressBar: isCustomQuote ? "customQuote" : 'quoteSummary' });
    }, [isCustomQuote]);

    return (
        <LeadGenerationForm
            config={config}
            onAnswerSelected={{
                [config.variables.fencingServicesAnswerId]: () => setIsCustomQuote(false),
                [config.variables.deckingServicesAnswerId]: () => setIsCustomQuote(true),
                [config.variables.interlockServicesAnswerId]: () => setIsCustomQuote(true),
                [config.variables.landscapingServicesAnswerId]: () => setIsCustomQuote(true),
                [config.variables.winterServicesAnswerId]: () => setIsCustomQuote(true),
            }}
            additionalPages={{
                customQuote: <CustomQuote />,
            }}
        />
    );
};

export default App;
