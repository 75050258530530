import { Button, Page, ILead } from "@carybit/lead-generation-form";

interface IProps {
    hidden?: boolean;
    lead?: ILead;
}

export const CustomQuote = ({ hidden, lead }: IProps) => {
    return (
        <Page hidden={hidden}>
            <div className="form custom-quote-page">
                <p
                    style={{
                        color: "white",
                        fontSize: "24px",
                        margin: "0 0 20px 0",
                        fontWeight: "500",
                    }}
                >
                    As of today, instant quotes are only available for <u>fencing services</u>.
                </p>

                <span
                    style={{
                        color: "white",
                        fontSize: "20px",
                        margin: "20px 0",
                    }}
                >
                    To get your free quote:
                </span>

                <div className="cta-container">
                    <div className="button-container" style={{ marginTop: "1.5rem", width: "unset", display: "flex", justifyContent: "center" }}>
                        <a href={`tel:+14167275563`}>
                            <Button text={"Call us 416 727-5563"}></Button>
                        </a>
                    </div>

                    <div className="middle-container">
                        <div className="line"></div>

                        <span
                            style={{
                                color: "white",
                                fontSize: "18px",
                            }}
                        >
                            Or
                        </span>

                        <div className="line"></div>
                    </div>

                    <div className="button-container" style={{ marginTop: "1.5rem", width: "unset", display: "flex", justifyContent: "center" }}>
                        <a href={`https://calendly.com/jasonsnowy/30min?email=${lead.email}&name=${lead.name}&location=${lead.phone}`}>
                            <Button text={"Book Your Free Consultation"}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" style={{ marginLeft: "0.25rem" }}>
                                    <path d="M23.12,9.91,19.25,6a1,1,0,0,0-1.42,0h0a1,1,0,0,0,0,1.41L21.39,11H1a1,1,0,0,0-1,1H0a1,1,0,0,0,1,1H21.45l-3.62,3.61a1,1,0,0,0,0,1.42h0a1,1,0,0,0,1.42,0l3.87-3.88A3,3,0,0,0,23.12,9.91Z" />
                                </svg>
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </Page>
    );
};